import { HttpClient } from '@wix/http-client';
import {
  getSiteRolloutStatus,
  markEcomCompatible,
} from '@wix/ambassador-bookings-v1-site-rollout-status/http';

export const ROLLOUT_MANAGER_BASE_URL = `/_api/bookings-rollout-manager-app`;

export class EditorScriptApi {
  constructor(private httpClient: HttpClient) {}

  async getIsBookingsOnEcom(): Promise<boolean> {
    const { data: siteRolloutStatusResponse } = await this.httpClient.request(
      getSiteRolloutStatus({}),
    );
    return siteRolloutStatusResponse.siteRolloutStatus.isBookingPlatformReady;
  }

  async setEcomCompatible(): Promise<void> {
    await this.httpClient.request(markEcomCompatible({}));
  }
}
