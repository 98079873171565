import { PageId } from '../constants';

export function addBookingsPage(pageId, editorSDK, appToken) {
  return editorSDK.document.tpa.add.component(appToken, {
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    page: {
      pageId,
      shouldNavigate: false,
    },
  });
}

function addStyledBookingsPage(pageId, style, editorSDK, appToken) {
  return editorSDK.document.tpa.add.component(appToken, {
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    copyStyle: true,
    styleId: style.id,
    page: {
      pageId,
      isHidden: true,
    },
  });
}

export function addBookCheckoutPage(
  editorSDK,
  appDefinitionId,
  appToken,
  originStyle,
) {
  return addStyledBookingsPage(
    PageId.BOOKINGS_CHECKOUT,
    originStyle,
    editorSDK,
    appToken,
  );
}
